import { Grid, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import DefaultTemplate from "@src/site/templates/default";
import { snake } from '@src/snake/core';
import { ghost } from "@src/utils/ghost";
import { window } from 'browser-monads';
//import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import shortid from 'shortid';
import clsx from 'clsx';
import Text from '@src/components/Text';

const useStyles = makeStyles((theme) => ({
  authOverlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    background: 'rgba(92, 34, 117, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: '1',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    }
  },
  hidden: {
    visibility: 'visible',
    display: 'none',
  },
  pinTitle: {
    color: 'white'
  },
  secretWrapper: {
    width: '400px',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '80%',
    }
  },
  input: {
    ...theme.fonts.faces.titleL,
    textAlign: 'center',
    fontSize: '3rem',
    '&::placeholder': {
      color: 'white'
    }
  },
  outlinedInput: {
    color: 'white',
    '&$focused $notchedOutline': {
      borderColor: `white !important`,
    },
    '&$error $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  focused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important'
  },
  error: {
  },
  subscribeButton: {
    height: 60,
    width: '100%',
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.medium,

  },
  passwordField: {
    width: 70,
    height: 70,
    margin: 10,
  }
}));

export default function SitemapPage() {

  const classes = useStyles();

  const page = {
    content: null,
    seo: {},
    tracking: {}
  }

  const storage = 'secret_auth';
  const check = 'fh00';
  const [auth, setAuth] = useState(false);
  const [secret, setSecret] = useState([]);

  const Pinpad = ({ conf }) => {
    return <TextField
      variant="outlined"
      type="password"
      className={classes.passwordField}
      placeholder="-"
      error={false}
      InputProps={{
        id: `id-${conf.id}`,
        classes: {
          input: classes.input,
          root: classes.outlinedInput,
          focused: classes.focused,
          notchedOutline: classes.notchedOutline,
          error: classes.error
        },
      }}
      onInput={handleInput}
    />
  }

  const Sitemap = () => {
    const [sitemap, setSitemap] = useState([]);
    const [checked, setChecked] = useState(false);

    const checkBugsnag = function () {
      if (window.gbugs) {
        setChecked(window.gbugs.hasQA());
      } else {
        setTimeout(checkBugsnag, 100);
      }
    }

    useEffect(() => {
      // QA status
      checkBugsnag();

      // GProxy
      ghost('gproxy').then(() => {
        console.log("GProxy is avaiable from now on using window.gproxy...", window.gproxy.session.native())
      });

      async function fetchData() {
        const result = await snake.getAllPages();
        let sitemap = {};
        // group by site
        result.data.map((item, i) => {
          let site = item.uri.split('/')[1];
          if (typeof sitemap[site] === 'undefined') {
            sitemap[site] = [];
          }
          sitemap[site].push(item);
          return true;
        });
        //console.log(sitemap);
        setSitemap(sitemap);
      }
      fetchData();
    }, []);

    const toggleChecked = () => {
      setChecked((prev) => !prev);
      window.gbugs.toggleQA(!checked)
    };

    return (
      <div style={{ padding: `1.5rem` }}>
        <h1>This is the sitamap for dev purposes!</h1>
        <FormControlLabel
          value="qa"
          control={<Switch color="primary" checked={checked} onChange={toggleChecked} />}
          label="QA Activation"
          labelPlacement="end"
        />
        <br />
        <h2>Dynamic CMS pages</h2>
        {Object.keys(sitemap).map((site, index) => {
          return (
            <div key={shortid.generate()}>
              <h3 key={shortid.generate()}>Site {site.toUpperCase()}</h3>
              <ul key={shortid.generate()}>
                {
                  sitemap[site].map((item, i) => {
                    return <li key={shortid.generate()}><a href={item.uri}>{item.title}</a></li>
                  })
                }
              </ul>
            </div>
          )
        })}
        <h2>Geoip auto-redirect</h2>
        <a href="/">Domain root page</a> <br />
        <h2>MyArea</h2>
        <a href="/uk/myarea/">MyArea UK</a> <br />
        <a href="/de/myarea/">MyArea DE (name to be defined)</a> <br />
        <h2>Prelaunch (WIP to be completed copy-paste from actual live)</h2>
        <a href="/uk">Prelaunch page UK</a> <br />
        <a href="/de">Prelaunch page DE</a> <br />
        <h2>Errors</h2>
        <a href="/404">Custom error 404</a> <br />
        <a href="/500">Custom error 500</a> <br />
      </div>
    )
  }

  const handleInput = (e) => {
    // limit to one numeric only character
    e.target.value = e.target.value.slice(-1);
    let id = e.target.getAttribute('id');
    let idx = id.split('-')[1];
    let pin = secret;
    pin[idx] = e.target.value;
    if (pin.join("").toLowerCase() === check) {
      // save cookie
      window.localStorage.setItem(storage, true);
      setAuth(true);
      return;
    }
    setSecret(pin)
    if (e.target.parentElement.parentElement.nextSibling) {
      e.target.parentElement.parentElement.nextSibling.firstChild.firstChild.focus();
    }
  }

  useEffect(() => {
    setAuth(window.localStorage.getItem(storage) === "true");
  }, [auth])

  return (
    <DefaultTemplate page={page}>
      <div className={clsx(classes.authOverlay, { [classes.hidden]: auth })} >
        <Grid className={classes.secretWrapper} spacing={2} container>
          <Grid xs={12} md={12} item>
            <Text className={classes.pinTitle} variant="titleXL">Restricted area</Text>
          </Grid>
          <Grid xs={12} md={12} item>
            <Pinpad conf={{ id: 0 }} />
            <Pinpad conf={{ id: 1 }} />
            <Pinpad conf={{ id: 2 }} />
            <Pinpad conf={{ id: 3 }} />
          </Grid>
        </Grid>
      </div>
      {auth && <Sitemap />}
    </DefaultTemplate>
  );
}
